import { get } from 'lodash';
import { Link as BaseLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ColorLookup, colors } from './colors';

export const HELVETICA = '"Helvetica Neue", Helvetica, Arial, sans-serif';

/* all __Gray components are deprecated for their default counterparts with kind='grayText' */
export interface TextProps {
  kind?: ColorLookup;
}

const textStyle = css<TextProps>`
  font-family: ${HELVETICA};
  font-size: 1em;
  line-height: 1.25em;
  color: ${({ kind = 'text' }) => get(colors, kind)};
`;

export const body = styled.p<TextProps>`
  ${textStyle}
  font-weight: normal;
  white-space: pre-wrap;
`;

export const span = styled.span<TextProps>`
  ${textStyle}
`;

export const bold = styled(span)<TextProps>`
  ${textStyle}
  font-weight: bold;
`;

export const h1 = styled.h1<TextProps>`
  ${textStyle}
  font-size: 2em;
  font-weight: 300;
`;

export const h1Bold = styled.h1<TextProps>`
  ${textStyle}
  font-size: 2em;
  font-weight: bold;
`;

export const h2 = styled.h2<TextProps>`
  ${textStyle}
  font-size: 1.5em;
  font-weight: bold;
`;

export const h3 = styled.h3<TextProps>`
  ${textStyle}
  font-size: 1.25em;
  font-weight: bold;
`;

export const h3grey = styled.h3<TextProps>`
  ${textStyle}
  font-size: 1.25em;
  font-weight: bold;
  color: ${colors.grey.ozGray2};
`;

export const h4 = styled.h4<TextProps>`
  ${textStyle}
  font-size: 1.0 em;
  font-weight: bold;
`;

export const h4grey = styled.h4<TextProps>`
  ${textStyle}
  font-size: 1.0 em;
  color: ${colors.grey.ozGray2};
`;

export const label = styled(body)`
  color: ${({ kind = 'grayText' }) => get(colors, kind)};
  font-size: 0.75em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.0625em;
  line-height: 0.9375em;
`;

export const bodyBold = styled(body)<TextProps>`
  font-weight: bold;
  color: ${({ kind = 'text' }) => get(colors, kind)};
`;

export const bodyGrey = styled(body)`
  color: ${({ kind = 'grayText' }) => get(colors, kind)};
`;
export const bodyGreyBold = styled(bodyGrey)`
  font-weight: bold;
`;
export const bodySmall = styled(body)<TextProps>`
  color: ${({ kind = 'text' }) => get(colors, kind)};
  font-size: 0.875em;
  line-height: 1.125em;
  white-space: pre-wrap;
`;

export const bodyCaption = styled(bodySmall)`
  font-size: 0.75em;
  line-height: 1.3em;
  font-weight: 500;
`;

export const bodySmallBold = styled(bodySmall)`
  font-weight: bold;
`;

export const bodySmallItalicized = styled(bodySmall)`
  font-style: italic;
`;

export const bodySmallGrey = styled(bodySmall)`
  color: ${({ kind = 'grayText' }) => get(colors, kind)};
`;
export const caption = styled(body)`
  font-size: 0.75em;
  line-height: 0.9375em;
`;

export const captionBold = styled(body)`
  font-size: 0.75em;
  line-height: 0.9375em;
  font-weight: bold;
`;

export const captionGrey = styled(caption)`
  color: ${({ kind = 'grayText' }) => get(colors, kind)};
`;

const linkStyle = css<TextProps>`
  border: none;
  background: none;
  font-family: ${HELVETICA};
  font-size: 1em;
  line-height: 1.25em;
  padding: 0;
  text-decoration: none;

  :hover:not(:disabled) {
    cursor: pointer;
    text-decoration: underline;
  }

  :disabled {
    color: ${colors.grey.border};
  }

  color: ${({ kind }) => (kind ? get(colors, kind) : colors.link)};
`;

export const link = styled(BaseLink)<TextProps>`
  ${linkStyle}
`;

// Since BaseLink expects the link to be a resource within the app
export const externalLink = styled.a<{ kind?: ColorLookup }>`
  ${linkStyle}
`;

export const linkCaption = styled(link)`
  ${linkStyle}
  font-size: 0.75rem;
  line-height: 1rem;
`;

export const linkButton = styled<'button'>(link as any).attrs({ as: 'button' })<TextProps>``;

export const linkButtonSmall = styled<'button'>(link as any).attrs({ as: 'button' })<TextProps>`
  font-size: 0.875em;
`;

export const breadcrumbLink = styled(BaseLink)`
  color: ${colors.text};
  text-decoration: underline;
`;

export const breadcrumbCurrent = styled.span`
  color: ${colors.grey.dark};
`;

export const noteTitle = styled.div`
  max-width: 25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;
