import React from 'react';
import { AppRoutes as EmbedRoutes } from './AppRoutes';
import { NativeBaseProvider } from './plugins/nativeBase';

export const AppEmbed = (): JSX.Element => {
  return (
    <NativeBaseProvider>
      <EmbedRoutes />
    </NativeBaseProvider>
  );
};
