import styled from 'styled-components';
import { ChevronRight } from 'baseui/icon';
import { StyledSpinnerNext } from 'baseui/spinner';
import { first } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { colors, colorMap, Text } from '../../globalStyles';
import {
  AdminAvailabilityQuery,
  AdminGetAllocationsQuery,
  Availability,
  AvailabilityBlockedReason,
  CalendarEvent,
  Organization,
  useAdminAvailabilityQuery,
  useAdminGetAllocationsQuery,
  User,
} from '../../graphQL';
import { BookingWizardData, ProviderWithUpcomingAvailability } from '../../Pages/Booking/types';
import { UnexpectedError } from '../../Pages/Shared';
import { Nullable } from '../../types';
import { getWeek, cx } from '../../utils';
import { FinalButton } from '../FinalButton';
import { ActiveWeek } from './ActiveWeek';
import { BlockedSlotModal } from './BlockedSlotModal';
import { BookableIntakesDepleted } from '../DedicatedGroupModel/BookableIntakesDepleted';
import { BookableFollowupsDepleted } from '../DedicatedGroupModel/BookableFollowupsDepleted';
import { When } from '../When';
import { useEvents, events } from '../Events/EventsProvider';
import { ProvidersSoonerAvailability } from '../ProviderNetwork/ProvidersSoonerAvailability';
import { InlineSVG } from '../Icons';

const DAYS_IN_WEEK = 7;

type SlotProvider = BookingWizardData['appointment']['provider'];

type TimePickerProps = {
  startDate: Moment;
  timezone: string;
  providers: ProviderWithUpcomingAvailability[];
  user: Pick<User, 'id'> & { organization?: Nullable<Pick<Organization, 'id'>> };
  appointment: BookingWizardData['appointment'];
  onChangeWeek: (increment: number) => void;
  onSelectTime: (
    time: Moment,
    provider: SlotProvider,
    organizationId?: number | null,
    isFeeForServiceTime?: boolean | null
  ) => void;
  displayAllHours?: boolean;
  reschedulingAppointment?: Pick<CalendarEvent, 'id'>;
  organizationName?: string;
  nextAvailableDGMDate?: Date;
  onSelectStartDate: (m: Moment) => void;
  soonerAvailabilityModalState: boolean;
  setSoonerAvailabilityModalState: (val: boolean) => void;
  dedicatedGroupModelActive: boolean;
  dgmHoursDepleted: boolean;
  wholeCampusCare?: boolean;
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 0.5fr repeat(${DAYS_IN_WEEK}, 1fr) 0.5fr;
`;

type TimeContainerProps = {
  hoursDepleted: boolean;
};

const TimeContainer = styled.div<TimeContainerProps>`
  background-color: #f9f9f9;
  padding-top: ${({ hoursDepleted }) => (hoursDepleted ? '0' : '1em')};
  padding-bottom: 1em;
`;

const DayColumn = styled.div`
  padding: 0 0.5em;
`;

const TimeButton = styled.button`
  border-color: ${colors.grey.border};
  background-color: ${colorMap.background[1]};
  color: ${colorMap.text[0]};
  width: 100%;
  margin-top: 0.5em;
  font-size: 1em;
  cursor: pointer;
  border-radius: 25px;
  padding: 0.75em 0.25em;
  &.highlighted {
    border-color: #0179ff;
    background-color: #e5f1ff;
  }
  &.blocked {
    border-color: ${colors.danger};
    background-color: ${colors.white};
    color: ${colors.danger};
  }
  &.unavailable {
    border-color: #e3e3e3;
    background-color: transparent;
    color: #e3e3e3;
    cursor: unset;
  }
`;

export function TimePicker({
  startDate,
  onChangeWeek,
  providers,
  appointment,
  onSelectTime,
  timezone,
  user,
  displayAllHours,
  reschedulingAppointment,
  organizationName,
  nextAvailableDGMDate,
  onSelectStartDate,
  soonerAvailabilityModalState,
  setSoonerAvailabilityModalState,
  dedicatedGroupModelActive,
  dgmHoursDepleted,
  wholeCampusCare = false,
}: TimePickerProps) {
  const [showBlockedModal, setShowBlockedModal] = useState<{
    reason: AvailabilityBlockedReason;
    args: [
      Readonly<Moment>,
      Readonly<SlotProvider>,
      Readonly<Availability['organizationId']>,
      Readonly<Availability['isFeeForServiceTime']>
    ];
  } | null>(null);
  const { track: trackEvent } = useEvents();

  const {
    data: availData,
    loading: availLoading,
    error,
  } = useAdminAvailabilityQuery({
    variables: {
      providerIds: providers.map(p => p.id),
      duration: appointment.duration!,
      careType: appointment.careType!,
      appointmentType: appointment.appointmentType!,
      start: startDate.format(),
      userId: user!.id,
      rescheduleId: reschedulingAppointment?.id,
      timezone,
    },
  });

  // show allocations if we're requesting time for one provider and the displayAllHours flag is set
  const provider = first(providers);
  const skipAllocations = !displayAllHours || providers.length > 1 || !provider;
  const { data: allocData, loading: allocLoading } = useAdminGetAllocationsQuery({
    variables: { providerId: provider?.id ?? 0 },
    skip: skipAllocations,
  });

  const [hoveredTime, setHoveredTime] = useState<Moment | null>(null);
  const hoveredEnd = hoveredTime ? hoveredTime.clone().add(appointment.duration!, 'minutes') : null;
  const days = getWeek(startDate);

  // computeTimeslotsByDay is memoized here because it's a bit on the expensive side
  // (lots of date comparisons to merge hours with availability)
  const timesByDay = useMemo(() => {
    if (!availData || (!allocData && !skipAllocations)) {
      return Array(DAYS_IN_WEEK)
        .fill(0)
        .map(() => []);
    }
    const userOrgId = user!.organization ? user!.organization.id : undefined;
    // Only use allocations for the user's organization
    const allocations = allocData?.adminGetAllocations.filter(alloc =>
      // TODO: [19324] Verify if this should be swapped out to improve UX for DGM allocations.
      // changing this to true should include DGM allocations
      // alloc.organization ? alloc.organization.id === userOrgId : true
      alloc.organization ? alloc.organization.id === userOrgId : !userOrgId
    );
    return computeTimeslotsByDay(
      availData.adminAvailability,
      allocations ?? null,
      startDate,
      timezone
    );
  }, [availData, skipAllocations, user, allocData, startDate, timezone]);

  const loading = availLoading || allocLoading;

  const orgHoursUnavailable = !availData?.adminAvailability?.data?.some(
    ({ organizationId }) => organizationId
  );

  const hoursDepletedBanner = (
    <When isTruthy={dgmHoursDepleted && orgHoursUnavailable}>
      {appointment.appointmentType === 'intake' ? (
        <BookableIntakesDepleted
          className="absolute top-0 left-0 right-0"
          onSelectStartDate={onSelectStartDate}
          nextAvailableDGMProviderDate={nextAvailableDGMDate}
          wholeCampusCare={wholeCampusCare}
        />
      ) : (
        <BookableFollowupsDepleted
          className="absolute top-0 left-0 right-0"
          onSelectStartDate={onSelectStartDate}
          organizationName={organizationName}
          nextAvailableDGMProviderDate={nextAvailableDGMDate}
          wholeCampusCare={wholeCampusCare}
        />
      )}
    </When>
  );

  useEffect(() => {
    if (loading || !availData) {
      return;
    }

    try {
      // send a front-end event that booking results were obtained
      trackEvent(events.search.providerSearchBookingResults, {
        providerId: provider?.id,
        availData,
        userId: user.id,
        appointmentType: appointment.appointmentType,
      });
    } catch (trackError) {
      // Do nothing with the error.
    }
  }, [availData]);

  // The no availability banner is for DGM orgs, but also supports DGM orgs that may be using
  // both DGM and legacy org hours
  const noAvailabilityBanner =
    !dgmHoursDepleted &&
    !orgHoursUnavailable &&
    dedicatedGroupModelActive &&
    nextAvailableDGMDate ? (
      <ProvidersSoonerAvailability
        setSoonerAvailabilityModalState={setSoonerAvailabilityModalState}
        soonerAvailabilityModalState={soonerAvailabilityModalState}
        nextAvailabilityButtonClick={onSelectStartDate}
        nextAvailableProviderDate={nextAvailableDGMDate}
      />
    ) : (
      <div className="flex flex-column justify-center items-center">
        <div className={cx(dgmHoursDepleted && 'o-40')}>
          <p className="mb3">No availability this week.</p>
          <FinalButton
            disabled={dgmHoursDepleted}
            onClick={() => (!dgmHoursDepleted ? onChangeWeek(1) : undefined)}
            kind="outline_black"
          >
            Jump to Next Week <ChevronRight size={20} />
          </FinalButton>
        </div>
      </div>
    );

  // This banner is shown if the patient's provider has no availability in the next 90 days.
  const providerHasNoAvailabilityBanner = (
    <div className="flex flex-column justify-center items-center">
      <div className="tc w-25 mv5">
        <InlineSVG kind="greyIcon" icon="alert-circle" size={42} />
        <Text.h3 className="mt2 mb4 ">Provider availability limited</Text.h3>
        <>
          <Text.bodyGrey className="mt2 mb2 ">
            This provider&apos;s availability is currently limited.
          </Text.bodyGrey>
          <Text.bodyGrey className="mt2 ">
            You may escalate this issue by contacting Mantra Partner Success team at
            <br />
            <Text.externalLink
              href="mailto:partnersuccess@mantrahealth.com"
              rel="noopener noreferrer"
              target="_blank"
              className="b "
            >
              partnersuccess@mantrahealth.com
            </Text.externalLink>
          </Text.bodyGrey>
        </>
      </div>
    </div>
  );

  // An MCP user in a DGM org booking for a patient whose provider has no availability in the next 3 months
  // should see the providerHasNoAvailabilityBanner, otherwise use the standard no availability banner.
  const condensedNoAvailabilityBanner =
    dedicatedGroupModelActive && !nextAvailableDGMDate
      ? providerHasNoAvailabilityBanner
      : noAvailabilityBanner;

  return (
    <>
      {showBlockedModal && (
        <BlockedSlotModal
          kind={showBlockedModal.reason}
          onClose={() => setShowBlockedModal(null)}
          onContinue={() => onSelectTime(...showBlockedModal.args)}
        />
      )}
      <div data-cy="time-picker">
        <div className={cx(dgmHoursDepleted && orgHoursUnavailable && 'o-40')}>
          <ActiveWeek
            forBooking={{
              providerId: providers[0].id,
              careType: appointment.careType!,
              apptType: appointment.appointmentType!,
            }}
            startDate={startDate}
            onChangeWeek={onChangeWeek}
            activeDays={timesByDay.filter(i => i.length > 0).map(i => i[0].time.day())}
            timezone={timezone}
          />
        </div>
        <TimeContainer hoursDepleted>
          {error && <UnexpectedError />}
          {loading && (
            <div className="flex justify-center">
              <StyledSpinnerNext />
            </div>
          )}
          {!loading && !error && timesByDay.every(x => x.length === 0) && (
            <div>
              {hoursDepletedBanner}
              {condensedNoAvailabilityBanner}
            </div>
          )}
          {!loading && !timesByDay.every(x => x.length === 0) && (
            <div>
              {hoursDepletedBanner}
              <Grid className={cx(dgmHoursDepleted && orgHoursUnavailable && 'o-40')}>
                <div />
                {days.map((day, dayIndex) => (
                  <DayColumn key={day.day()}>
                    {timesByDay[dayIndex].map(
                      ({
                        time,
                        available,
                        provider: slotProvider,
                        blockers,
                        organizationId: slotOrgId,
                        isFeeForServiceTime,
                      }) => {
                        // slotOrgId: a slot having an orgId means it's not DGM hours, it's org hours
                        const canScheduleSlot = available && (slotOrgId || !dgmHoursDepleted);
                        const buttonClasses = {
                          highlighted: Boolean(
                            hoveredTime &&
                              hoveredEnd &&
                              hackyButFastIsBetween(time, hoveredTime, hoveredEnd)
                          ),
                          unavailable: !canScheduleSlot,
                          blocked: Boolean(blockers.length),
                        };
                        return (
                          <TimeButton
                            className={cx(buttonClasses)}
                            data-cy="availability-slot"
                            key={time.format('h:mm A')}
                            onMouseOver={() => canScheduleSlot && setHoveredTime(time)}
                            onFocus={() => canScheduleSlot && setHoveredTime(time)}
                            onMouseOut={() => setHoveredTime(null)}
                            onBlur={() => setHoveredTime(null)}
                            disabled={!canScheduleSlot}
                            onClick={() =>
                              blockers.length
                                ? setShowBlockedModal({
                                    reason: blockers[0],
                                    args: [time, slotProvider, slotOrgId, isFeeForServiceTime],
                                  })
                                : onSelectTime(time, slotProvider, slotOrgId, isFeeForServiceTime)
                            }
                          >
                            {time.format('h:mm A')}
                          </TimeButton>
                        );
                      }
                    )}
                  </DayColumn>
                ))}
                <div />
              </Grid>
            </div>
          )}
        </TimeContainer>
      </div>
    </>
  );
}

export function hackyButFastIsBetween(m: Moment, start: Moment, end: Moment) {
  // checks if m is equal to or after start and before end.
  //
  // the "correct" way to check this would be something like:
  //
  // start.isBeforeOrSame(m) && end.isAfter(m)
  //
  // but in practice, that's pretty slow for some reason and slows down
  // the rendering of the component, and comparing epochs is much faster
  // and seems to work for our purposes. this is only used for deciding
  // which times to highlight when hovering, so if it does have a bug,
  // it shouldn't be the end of the world
  return start.valueOf() <= m.valueOf() && end.valueOf() > m.valueOf();
}

type TimeSlot = {
  time: Moment;
  available: boolean;
  provider: NonNullable<BookingWizardData['appointment']['provider']>;
  blockers: AvailabilityBlockedReason[];
  organizationId?: number | null;
  isFeeForServiceTime?: boolean | null;
};

export function computeTimeslotsByDay(
  availability: AdminAvailabilityQuery['adminAvailability'],
  allocations: AdminGetAllocationsQuery['adminGetAllocations'] | null,
  startDate: Moment,
  timeZone: string
): TimeSlot[][] {
  const days = getWeek(startDate);
  const endDate = startDate.clone().add(1, 'week');
  const slots: TimeSlot[] = [];
  // We need a hashmap for time slots or matching them up with availability
  // later is very slow in practice (because it's O(N^2))
  const cache: Record<number, TimeSlot> = {};

  // Create a timeslot for every possible time in the provider's working hours
  if (allocations) {
    for (const alloc of allocations) {
      const aStart = moment(alloc.startTime);
      const aEnd = moment(alloc.endTime);
      const aRepeatsUntil = alloc.repeatsUntil && moment(alloc.repeatsUntil);
      let d: Moment | undefined;
      let end: Moment | undefined;
      if (!alloc.weekly && aStart.isSame(startDate, 'week')) {
        d = aStart.clone();
        end = aEnd.clone();
      } else if (
        alloc.weekly &&
        aStart.isSameOrBefore(startDate, 'week') &&
        (!aRepeatsUntil || aRepeatsUntil.isSameOrAfter(endDate, 'week'))
      ) {
        d = startDate
          .clone()
          .day(aStart.day())
          .hour(aStart.hour())
          .minute(aStart.minute())
          .second(0)
          .millisecond(0);
        end = d.clone().hour(aEnd.hour()).minute(aEnd.minute());
      }
      if (d && end) {
        while (d < end) {
          const slot = {
            time: d.tz(timeZone).clone(),
            available: false,
            provider: alloc.provider,
            blockers: [],
            organizationId: alloc.organization?.id,
            isFeeForServiceTime: alloc.isFeeForServiceTime,
          };
          slots.push(slot);
          cache[d.valueOf()] = slot;
          d.add(availability.interval, 'minutes');
        }
      }
    }
  }

  // Mark the timeslots which are available as such
  for (const time of availability.data) {
    const slot = cache[moment(time.start).valueOf()];
    if (slot) {
      slot.available = true;
      slot.blockers = time.blockers;
    } else {
      const newSlot = {
        time: moment(time.start).clone().tz(timeZone),
        provider: time.provider,
        available: true,
        blockers: time.blockers,
        organizationId: time.organizationId,
        isFeeForServiceTime: time.isFeeForServiceTime,
      };
      slots.push(newSlot);
      cache[moment(time.start).valueOf()] = newSlot;
    }
  }

  // Map timeslots to days
  const timesByDay = days.map(day => slots.filter(({ time }) => time.date() === day.date()));
  // Sort timeslots within each day
  timesByDay.forEach(day => day.sort((a, b) => a.time.diff(b.time)));
  return timesByDay;
}
