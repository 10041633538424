import moment from 'moment';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { PillLabel } from '../../Components/Labels';
import { LoadingPage } from '../../Components/LoadingOverlay';
import { colors, PaddedPage, Text } from '../../globalStyles';
import {
  AppView,
  CareType,
  OrganizationUtilizationWeek,
  Permission,
  useOrganizationUtilizationQuery,
  UtilizationHours,
} from '../../graphQL';
import { compareJsDates } from '../../utils';
import { useOrganizationId } from '../Organizations/util';
import { PageTitle, UnexpectedError } from '../Shared';
import { formatCarePeriodText } from './CarePeriodUtilization';
import { CarePeriodSubset, FrontendCarePeriodUtilization } from './UtilizationPage';
import { Tab, Tabs } from '../../Components/Tabs';
import { Icon } from '../../Components/Icons';
import { useCurrentProvider } from '../../Components/Permissions';

export const MCPUtilizationPage = () => {
  const organizationId = useOrganizationId();
  const { hasPermission, showProviderUtilizationTab, currentProvider, appView } = useCurrentProvider();
  const [activeTab, setActiveTab] = useState<string>();
  const [carePeriodUtilizations, setCarePeriodUtilizations] =
    useState<FrontendCarePeriodUtilization[]>();

  let currentCarePeriod: CarePeriodSubset | null = null;

  let relevantWeeklyUtilizationRecords: OrganizationUtilizationWeek[] = [];

  const { data, loading, error } = useOrganizationUtilizationQuery({
    variables: { organizationId },
    onCompleted: () => {
      setActiveTab(data?.organization.careFlows[0].careType.toLowerCase() ?? 'default');
      const carePeriodData =
        data?.organization.organizationUtilization?.carePeriodUtilizations.sort((a, b) =>
          compareJsDates({ b: a.carePeriod.startDate, a: b.carePeriod.startDate })
        );
      setCarePeriodUtilizations(carePeriodData);
    },
  });

  const careTypes: CareType[] =
    data?.organization.careFlows.map((flow: { careType: CareType }) => flow.careType) ?? [];

  if (carePeriodUtilizations && carePeriodUtilizations.length > 0) {
    if (moment(carePeriodUtilizations[0].carePeriod.startDate).isSameOrBefore(moment())) {
      // get utilization records for this week and the next three weeks
      const tmp = carePeriodUtilizations[0].weeklyUtilizationRecords.filter(utilizationRecord => {
        return (
          moment(utilizationRecord.startDate) >= moment().startOf('week') &&
          moment(utilizationRecord.startDate) <= moment().startOf('week').add(3, 'weeks') &&
          moment(utilizationRecord.startDate) <=
            moment(carePeriodUtilizations[0].carePeriod.endDate)
        );
      });
      currentCarePeriod = carePeriodUtilizations[0].carePeriod;
      relevantWeeklyUtilizationRecords = tmp;
    }
  }

  const tabs: Tab<string>[] = [];
  for (const careType of careTypes) {
    tabs.push({
      title: careType,
      key: careType.toLowerCase(),
      render: () =>
        MCPUtilizationDashboard({
          careType,
          utilizationRecords: relevantWeeklyUtilizationRecords,
        }),
    });
  }

  const TabsComponent = () => {
    return (
      <Tabs
        initialTab={activeTab}
        config={{ tabpanel: ({ children }) => <div className="pa4">{children}</div> }}
        onChange={tab => setActiveTab(tab)}
        tabs={tabs}
      />
    );
  };

  // its fine if the oz admins can see this page
  if (
    !hasPermission(Permission.UtilizationView) ||
    ((appView === AppView.Mcp || appView === AppView.Referral) &&
    !showProviderUtilizationTab())
  ) {
    return <Redirect to="/" />;
  }

  if (loading || !data) return <LoadingPage />;
  if (error || !careTypes.length) return <UnexpectedError />;
  if (data && !currentCarePeriod) return <div>No Current Care Period</div>;

  return (
    <PaddedPage style={{ minWidth: '1050px' }}>
      {/* anchor for logo */}
      <div className="relative">
        {/* {orgId && orgId > 0 && <UniversityLogo organizationId={orgId} right="0" />} */}
        <div className="flex flex-row items-center">
          <PageTitle className="mr3">Utilization</PageTitle>
          <PillLabel>
            <Text.label>Beta</Text.label>
          </PillLabel>
        </div>

        <div className="mb2">
          {data?.organization.parent && (
            <Text.body>
              Inherits Utilization from {data.organization.parent.name ?? 'Parent'}
            </Text.body>
          )}
        </div>

        <div>
          {currentCarePeriod && (
            <Text.h4grey className="mb4">{formatCarePeriodText(currentCarePeriod)}</Text.h4grey>
          )}
        </div>
      </div>

      <TabsComponent />
    </PaddedPage>
  );
};

type MCPUtilizationDashboardProps = {
  careType: CareType;
  utilizationRecords: OrganizationUtilizationWeek[];
};
export const MCPUtilizationDashboard = ({
  careType,
  utilizationRecords,
}: MCPUtilizationDashboardProps) => {
  // test that we don't show weeks from subsequent care periods
  const hoursKey = `${careType.toLowerCase()}Hours` as keyof OrganizationUtilizationWeek;

  return (
    <div>
      <Text.label className="pb3">
        NEXT {`${utilizationRecords.length} WEEK${utilizationRecords.length > 1 ? 'S' : ''}`}
      </Text.label>
      <MCPUtilizationTable className="br2 w-90">
        <thead>
          <TableHeader>
            <TableHeaderSection className="pa4"> Week</TableHeaderSection>
            <TableHeaderSection className="pt4 pb4">
              <div className="flex items-center">
                <Icon icon="iconsCalendarCheckSvg" size={22} className="mr2" />
                Scheduled Bookable Hours
              </div>
            </TableHeaderSection>
            <TableHeaderSection className="pt4 pb4">
              <div className="flex items-center">
                <Icon icon="iconsBlackTimerSvg" size={22} className="mr2" />
                Avail Bookable Intakes (By Week)
              </div>
            </TableHeaderSection>
          </TableHeader>
        </thead>
        <tbody>
          {utilizationRecords.map((allocationRow, rowIndex) => {
            const relevantHours = allocationRow[hoursKey] as UtilizationHours;
            if (!relevantHours) {
              return <tr />;
            }

            // Our system allows for appointments *in the future* to be marked as complete.
            // However, for display in both Oz and MCP, we've made a product decision to not include those
            // when summing up around utilization data. Future weeks only count upcoming appointments.
            // You can see where these backend values are populated in organizationUtilization.resolver.ts
            // Thus, it is safe for us to sum both Utilized and Scheduled here, since we know we are accounting for that
            const overallUsed =
              (relevantHours.hoursUtilized ?? 0) + (relevantHours.hoursScheduled ?? 0);
            const intakesUsed =
              (relevantHours.intakesUtilized ?? 0) + (relevantHours.intakesScheduled ?? 0);
            const followUpsUsed = overallUsed - intakesUsed;

            const overallRemaining = (relevantHours.maxHours ?? 0) - overallUsed;
            // need to modify this so that it takes the min of intakesRemaining and Followups Remaining
            const intakesRemaining = Math.min(
              overallRemaining,
              (relevantHours.maxIntakeHours ?? 0) - intakesUsed
            );

            return (
              <tr key={rowIndex}>
                <MCPUtilizationDataPoint className="pa4 pb5">
                  {moment(allocationRow.startDate).format('MM/DD/YY')} -{' '}
                  {moment(allocationRow.endDate).format('MM/DD/YY')}
                </MCPUtilizationDataPoint>
                <MCPUtilizationDataPoint>
                  <div className="flex flex-column">
                    <div className="flex items-center">
                      <Text.bodyBold>{overallUsed}h </Text.bodyBold>
                      <Text.bodyGrey className="i">
                        {relevantHours.maxHours &&
                          `(${Math.round((overallUsed * 100) / relevantHours.maxHours)}% used of ${
                            relevantHours.maxHours
                          }h)`}
                      </Text.bodyGrey>
                    </div>
                    <Text.bodySmall>{intakesUsed}h Intakes</Text.bodySmall>
                    <Text.bodySmall>{followUpsUsed}h Follow Ups</Text.bodySmall>
                  </div>
                </MCPUtilizationDataPoint>
                <MCPUtilizationDataPoint className="pa4 pb5">
                  <Text.bodyBold>{Math.floor(intakesRemaining)} Intakes Available</Text.bodyBold>
                </MCPUtilizationDataPoint>
              </tr>
            );
          })}
          <WarningRow>
            <td colSpan={3}>
              {' '}
              <Text.body className="pa3">
                <b>Note:</b> Future weeks may appear to have low utilization due to{' '}
                <b>time reserved for existing students in care.</b>
              </Text.body>
            </td>
          </WarningRow>
        </tbody>
      </MCPUtilizationTable>
      <Text.body className="mt5">
        If you have questions about intake availability, please contact your Mantra Partner Success
        Manager.
      </Text.body>
    </div>
  );
};

const MCPUtilizationTable = styled.table`
  border: 2px solid ${colors.grey.lightBorder};
  border-collapse: collapse;
`;

const TableHeader = styled.tr`
  background: ${colors.background};
`;

const TableHeaderSection = styled.th`
  text-align: left;
`;

const MCPUtilizationDataPoint = styled.td`
  border-top: 2px solid ${colors.grey.lightBorder};
  border-bottom: 2px solid ${colors.grey.lightBorder};
  border-collapse: collapse;
  background: ${colors.white};
`;

const WarningRow = styled.tr`
  background: ${colors.warningBg};
`;
