import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useCurrentProvider } from './Components/Permissions';
import { Permission } from './graphQL';
import { Activity } from './Pages/Activity/Activity';
import { AppointmentsPage } from './Pages/Appointments';
import { Booking } from './Pages/Booking/BookingWizard';
import { SelfPayReferralBookingWizard } from './Pages/Booking/SelfPayReferralBooking/SelfPayReferralWizard';
import { CampusAnnouncementsFromParams } from './Pages/CampusAnnouncements/CampusAnnouncements';
import { CampusResourcesPage } from './Pages/CampusResources/CampusResourcesPage';
import { ConfigPage } from './Pages/Config/ConfigPage';
import { CounselorDashboard } from './Pages/CounselorDashboard';
import { DdhCrisisReport } from './Pages/DdhCrisisReports/DdhCrisisReports';
import { DebugPage } from './Pages/Debug';
import { HandbookFromParams } from './Pages/Handbook/Handbook';
import { Home } from './Pages/Home';
import { MessagesPage } from './Pages/Messages/MessagesPage';
import { CreateOrganization } from './Pages/Organizations/Create';
import { OrganizationAdmin } from './Pages/Organizations/OrganizationAdmin';
import { OrganizationMantraProvider } from './Pages/Organizations/OrganizationMantraProvider';
import { Organizations } from './Pages/Organizations/Organizations';
import { ProviderPage } from './Pages/Provider';
import { NewProvider } from './Pages/Provider/NewProvider';
import { ProviderList, UniStaffList } from './Pages/Provider/ProviderList';
import { ProviderNetwork } from './Pages/ProviderNetwork/ProviderNetwork';
import { ProviderSettings } from './Pages/ProviderSettings';
import { ReferralsPage } from './Pages/Referrals/ReferralsPage';
import { Dashboard } from './Pages/Reporting/Dashboard';
import { ChangePassword, ForgotPassword, Login } from './Pages/Session';
import { PasswordlessHandler } from './Pages/Session/PasswordlessHandler';
import { ResetPassword } from './Pages/Session/ResetPassword';
import { Unsubscribe } from './Pages/Session/Unsubscribe';
import { AddStaffPage } from './Pages/Staff/AddStaffPage';
import { StaffPage } from './Pages/Staff/StaffPage';
import { StudentsPage } from './Pages/Students';
import { TasksPage } from './Pages/Tasks';
import { TermsOfUse } from './Pages/Terms';
import { Tutorial } from './Pages/Tutorial/Tutorial';
import { UsersPage } from './Pages/UserList/UsersPage';
import { SuggestedProviders } from './Pages/Users/SuggestedProviders/SuggestedProviders';
import { UserDrilldown } from './Pages/Users/UserDrilldown';
import { MCPUtilizationPage } from './Pages/Utilization/MCPUtilizationPage';
import { VerificationPage } from './Pages/Verification';
import { EnrollSelection } from './Pages/Students/EnrollSelection';
import { EnrollTogetherAll } from './Pages/Students/EnrollSelection/EnrollTogetherall';
import { EnrollOptionCharlieHealth } from './Pages/Students/EnrollSelection/EnrollOptionCharlieHealth';
import { OnDemandCallReports } from './Pages/OnDemandCallReports/OnDemandCallReports';
export const PublicRoutes: React.FC = ({ children }) => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/forgotPassword" component={ForgotPassword} />
    <Route path="/resetPassword" component={ResetPassword} />
    <Route path="/passwordless" component={PasswordlessHandler} />
    <Route path="/unsubscribe" component={Unsubscribe} />
    <Route exact path="/changePassword" component={ChangePassword} />
    {children}
  </Switch>
);

export const AuthenticatedRoutes = () => (
  <Switch>
    <Route exact path="/debug/:id?" component={DebugPage} />
    <Route exact path="/users" component={UsersPage} />
    <Route exact path="/usersearch" component={UsersPage} />
    <Route exact path="/users/:id" component={UserDrilldown} />
    <Route exact path="/providers" component={ProviderList} />
    <Route exact path="/providers/uni" component={UniStaffList} />
    <Route exact path="/providers/new" component={NewProvider} />
    <Route path="/providers/:id" component={ProviderPage} />
    <Route exact path="/activity" component={Activity} />
    <Route exact path="/crisisreports" component={DdhCrisisReport} />
    <Route exact path="/odes-reports" component={OnDemandCallReports} />
    <Route exact path="/messages" component={MessagesPage} />
    <Route path="/appointments" component={AppointmentsPage} />
    <Route path="/verification" component={VerificationPage} />
    <Route path="/book" component={Booking} />
    <Route path="/home" component={Home} />
    <Route path="/tasks" component={TasksPage} />
    <Route path="/settings" component={ProviderSettings} />
    <Route exact path="/organizations" component={Organizations} />
    <Route exact path="/organizations/:organizationId/admin" component={OrganizationAdmin} />
    <Route
      exact
      path="/organizations/:organizationId/provider"
      component={OrganizationMantraProvider}
    />
    <Route exact path="/organizations/:organizationId/students" component={StudentsPage} />
    <Route exact path="/organizations/:organizationId/referrals" component={ReferralsPage} />
    <Route
      exact
      path="/organizations/:organizationId/students/enroll"
      component={hasPermissionsHLC(EnrollSelection, { perms: [Permission.PatientCreate] })}
    />
    <Route
      exact
      path="/organizations/:organizationId/students/enroll-option-iop"
      component={hasPermissionsHLC(EnrollOptionCharlieHealth, {
        perms: [Permission.PatientCreate],
      })}
    />
    <Route
      exact
      path="/organizations/:organizationId/students/enroll-togetherall"
      component={hasPermissionsHLC(EnrollTogetherAll, { perms: [Permission.PatientCreate] })}
    />
    <Route exact path="/organizations/:organizationId/staff" component={StaffPage} />
    <Route exact path="/organizations/staff/add" component={AddStaffPage} />
    <Route exact path="/organizations/:organizationId/handbook" component={HandbookFromParams} />
    <Route exact path="/organizations/:organizationId/resources" component={CampusResourcesPage} />
    <Route
      exact
      path="/organizations/:organizationId/updates"
      component={CampusAnnouncementsFromParams}
    />
    <Route exact path="/organizations/create" component={CreateOrganization} />
    <Route
      exact
      path="/students"
      component={() => <OrganizationRedirect path="/organizations/:organizationId/students" />}
    />
    <Route
      exact
      path="/referrals"
      component={() => <OrganizationRedirect path="/organizations/:organizationId/referrals" />}
    />
    <Route
      exact
      path="/staff"
      component={() => <OrganizationRedirect path="/organizations/:organizationId/staff" />}
    />
    <Route exact path="/organizations/:organizationId/dashboard" component={CounselorDashboard} />
    <Route
      exact
      path="/dashboard"
      component={() => <OrganizationRedirect path="/organizations/:organizationId/dashboard" />}
    />
    <Route exact path="/organizations/:organizationId/reporting" component={Dashboard} />
    <Route
      exact
      path="/reporting"
      component={() => <OrganizationRedirect path="/organizations/:organizationId/reporting" />}
    />
    <Route exact path="/organizations/:organizationId/utilization" component={MCPUtilizationPage} />
    <Route
      exact
      path="/utilization"
      component={() => <OrganizationRedirect path="/organizations/:organizationId/utilization" />}
    />
    <Route exact path="/config" component={ConfigPage} />
    <Route exact path="/terms" component={TermsOfUse} />
    <Route exact path="/provider-network/suggested-providers" component={SuggestedProviders} />
    <Route exact path="/provider-network" component={ProviderNetwork} />
    <Route exact path="/provider-network/book" component={SelfPayReferralBookingWizard} />
    <Route exact path="/tutorial" component={Tutorial} />
    <Route path="*" component={CatchAll} />
  </Switch>
);

const OrganizationRedirect = ({ path }: { path: string }) => {
  const { currentProvider } = useCurrentProvider();
  // someday will need a UX for org selection
  const organizationId = currentProvider.organizations[0].id.toString();
  const pathWithOrgId = path.replace(':organizationId', organizationId);
  return <Redirect to={pathWithOrgId} />;
};

const CatchAll = () => {
  const { appView } = useCurrentProvider();

  if (appView === 'oz') return <Redirect to="/home" />;
  if (appView === 'referral') return <Redirect to="/students" />;

  // we are assuming that MCP users only have one org
  // const mcpOrg = currentProvider.organizations[0];

  /*
  As of 01/14/22 SuperAdmins should not have access to "/home", redirect to "/dashboard"
  if (isProviderSuperAdmin(currentProvider))
    return (
      <Redirect
        to={hasPermission(Permission.Reporting) ? '/home' : `/organizations/${mcpOrg.id}/students`}
      />
    );
  */
  return <Redirect to="/dashboard" />;
};

type HasPermissionsOpts = {
  perms: Permission[];
  redirect?: string;
};

function hasPermissionsHLC<T extends {}>(C: React.FC<T>, { perms, redirect }: HasPermissionsOpts) {
  return (props: T) => {
    const { hasPermission } = useCurrentProvider();
    if (perms.some(p => !hasPermission(p))) {
      return <Redirect to={redirect ?? '/'} />;
    }
    return <C {...props} />;
  };
}
