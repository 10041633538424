import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import React, { Fragment, useState } from 'react';
import { useMarkSkillAssignmentSeenByAssignerMutation } from '../../../../graphQL';
import { getStylesheet } from '../../../../styles';
import { Button, Divider, DividerSoft, Heading, Layout, Text } from '../../../core';
import { useSeenAssignments } from '../hooks/useSeenAssignments';
import { SkillAssignments } from '../skillAssignmentTypes';
import { GrayBox } from './GrayBox';

type CompletedSkillsProps = {
  skillAssignments: SkillAssignments;
  patientName: string;
};

export const CompletedSkills = ({
  skillAssignments,
  patientName,
}: CompletedSkillsProps): JSX.Element => {
  const [openAssignment, setOpenAssignment] = useState<number | undefined>();
  const { isAssignmentSeen, markAssignmentSeen } = useSeenAssignments();

  const [apiMarkSeen] = useMarkSkillAssignmentSeenByAssignerMutation();

  if (skillAssignments.length < 1) {
    return (
      <GrayBox>
        <Text.para>{patientName} has not completed any skills.</Text.para>
      </GrayBox>
    );
  }

  const onViewPress = (skillAssignmentId: number, hasSeen: boolean) => (): void => {
    if (!hasSeen) {
      markAssignmentSeen(skillAssignmentId);

      void apiMarkSeen({
        variables: {
          skillAssignmentId,
        },
      });
    }

    setOpenAssignment(skillAssignmentId);
  };
  const onClosePress = (): void => {
    setOpenAssignment(undefined);
  };

  return (
    <Layout.Box>
      <DividerSoft />

      <Layout.VStack divider={<DividerSoft />}>
        {skillAssignments.map(assignedSkill => {
          const isOpen = openAssignment === assignedSkill.id;
          const hasSeen = isAssignmentSeen(assignedSkill.id) || assignedSkill.assignerHasSeen;
          const createdAt = format(parseISO(assignedSkill.createdAt), 'MMM dd');
          const assignedAt = format(parseISO(assignedSkill.createdAt), 'EEEE, M/dd/yy @ h:mm aaa');
          const completedAt =
            assignedSkill.completedAt != null
              ? format(parseISO(assignedSkill.completedAt), 'EEEE, M/dd/yy @ h:mm aaa')
              : 'Not Completed';

          return (
            <Fragment key={assignedSkill.id}>
              <Layout.HStack {...styles.assignedSkillBox} space={4}>
                <Text.para bold={!hasSeen}>{createdAt}</Text.para>

                <Text.para bold={!hasSeen} flex={1}>
                  {assignedSkill.skillTitle}
                </Text.para>

                <Layout.HStack>
                  {isOpen ? (
                    <Button.primarySmall
                      testID="button-assigned-skills-close"
                      onPress={onClosePress}
                    >
                      Close
                    </Button.primarySmall>
                  ) : (
                    <Button.primarySmall
                      testID="button-assigned-skills-view"
                      onPress={onViewPress(assignedSkill.id, hasSeen)}
                    >
                      View
                    </Button.primarySmall>
                  )}
                </Layout.HStack>
              </Layout.HStack>

              {isOpen && (
                <Layout.VStack
                  {...styles.assignedSkillInfo}
                  divider={<Divider backgroundColor="secondary.300" />}
                >
                  <Layout.HStack {...styles.assignedSkillInfoItem} space={2}>
                    <Layout.VStack space={2}>
                      <Text.para bold>Assigned:</Text.para>
                      <Text.para bold>Completed:</Text.para>
                    </Layout.VStack>

                    <Layout.VStack space={2}>
                      <Text.para>{assignedAt}</Text.para>
                      <Text.para>{completedAt}</Text.para>
                    </Layout.VStack>
                  </Layout.HStack>

                  {assignedSkill.skillReflection?.questions.map(({ question, answer }) => (
                    <Layout.VStack {...styles.assignedSkillInfoItem} key={question} space={4}>
                      <Heading.h5>{question}</Heading.h5>

                      {answer !== '' ? (
                        <Text.para>{answer}</Text.para>
                      ) : (
                        <Text.para italic color="secondary.600">
                          No response.
                        </Text.para>
                      )}
                    </Layout.VStack>
                  ))}
                </Layout.VStack>
              )}
            </Fragment>
          );
        })}
      </Layout.VStack>

      <DividerSoft />
    </Layout.Box>
  );
};

const styles = getStylesheet({
  assignedSkillBox: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingY: 4,

    // @ts-expect-error - Gap works but native base doesn't know that yet.
    gap: 4,
  },

  assignedSkillInfo: {
    backgroundColor: 'secondary.200',
  },

  assignedSkillInfoItem: {
    justifyContent: 'flex-start',
    padding: 4,
  },
});
