import { isNil, omitBy } from 'lodash';
import moment from 'moment';
import qs from 'query-string';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useConfigContext } from '../../../Components/ConfigContext';
import { FinalButton } from '../../../Components/FinalButton';
import { RadioRHF, SelectRHF } from '../../../Components/Form';
import { hasOrganizations, useCurrentProvider } from '../../../Components/Permissions';
import { useNetworkSearchOptions } from '../../../Components/ProviderNetwork/hooks';
import { isSelfPayReferral } from '../../../Components/ProviderNetwork/providerNetworkUtils';
import { Text } from '../../../globalStyles';
import { CareType } from '../../../graphQL';
import { useGeoStateAvailability } from '../../../Hooks/useGeoStateAvailability';
import { stateOptions } from '../../../states';
import { Nullable } from '../../../types';
import { WidgetContainer } from '../styles';

export const ProviderNetworkWidget = () => {
  const { currentProvider } = useCurrentProvider();

  if (!hasOrganizations(currentProvider)) {
    return null;
  }

  return <ProviderNetworkSearch />;
};

const ProviderNetworkSearch = () => {
  const { currentProvider, providerOrgUsesDedicatedGroupModel } = useCurrentProvider();
  const history = useHistory();
  const { stateHasCare } = useConfigContext();
  const { careTypes, payers } = useNetworkSearchOptions();

  const organization = currentProvider.organizations[0];
  const { loading: validateLoading, validateStateCareTypes } = useGeoStateAvailability(
    organization.id,
    { checkProviders: true }
  );

  const form = useForm<{ careType: CareType; state?: string; payerId?: string; endTime?: Date }>({
    defaultValues: { careType: CareType.Psychiatry },
  });
  const { state, careType } = form.watch(['state', 'careType']);

  const validationResponse = useMemo(() => {
    if (!validateStateCareTypes || !state) {
      return undefined;
    }

    return validateStateCareTypes(state)([careType]);
  }, [validateLoading, state, careType, organization.id]);

  const validationPassed = validationResponse?.passed;

  const isSelfPayOrg = isSelfPayReferral(currentProvider, careType);

  const submit = form.handleSubmit(({ payerId, ...argz }) => {
    argz.endTime = moment().add(3, 'months').toDate();
    const paramsRaw = { ...argz, payerId: isSelfPayOrg ? payerId : undefined };
    const params = qs.stringify(omitBy(paramsRaw, isNil));
    history.push(`/provider-network?${params}`);
  });

  // I am not sure if we can remove this yet, because stateHasCare checks for DTC validity while
  // validateStateCareTypes only checks the organization. Does this page ever interact with DTC?
  let invalidMsg: Nullable<string> = null;
  if (
    !providerOrgUsesDedicatedGroupModel() &&
    state &&
    careType &&
    !stateHasCare(careType, state, isSelfPayOrg ? null : organization)
  ) {
    const label = careType === CareType.Psychiatry ? 'psychiatric' : 'therapy';
    invalidMsg = `Mantra does not yet have any ${label} providers in the selected state.`;
  }

  const disabled: boolean = (() => {
    if (!validationPassed) {
      return true;
    }

    if (providerOrgUsesDedicatedGroupModel()) return false;
    return Boolean(!state || !!invalidMsg);
  })();

  return (
    <WidgetContainer>
      <Text.h3 className="mb3">Explore Providers</Text.h3>
      <FormProvider {...form}>
        <div className="flex flex-row justify-between flex-wrap gap-1">
          <div>
            <Text.bodySmallBold className="b mb2">Care Type</Text.bodySmallBold>
            <RadioRHF name="careType" options={careTypes} withLabel optionsInline />
          </div>
          <div className="flex flex-row flex-wrap gap-3 items-end">
            <MaxWidth>
              <Text.bodySmallBold className="b mb2">Patient State</Text.bodySmallBold>
              <SelectRHF
                iconLeft="iconsLocationPinSvg"
                hideKaret
                placeholder="State"
                options={stateOptions}
                clearable
                name="state"
                rules={{ required: true }}
              />
            </MaxWidth>
            {isSelfPayOrg && (
              <MaxWidth>
                <Text.bodySmallGrey className="b mb2">
                  Patient Insurance <span className="i">(optional)</span>
                </Text.bodySmallGrey>
                <SelectRHF
                  iconLeft="iconsPaymentSvg"
                  hideKaret
                  placeholder="Insurance"
                  options={payers}
                  clearable
                  name="payerId"
                />
              </MaxWidth>
            )}
            <div className="mb3">
              <FinalButton onClick={submit} disabled={disabled}>
                Search
              </FinalButton>
            </div>
          </div>
        </div>
      </FormProvider>
      {invalidMsg && validationPassed && <Text.body kind="danger">{invalidMsg}</Text.body>}
      {validationResponse?.organizationFailed && (
        <Text.body kind="danger">
          Your organization is not contracted to refer students for{' '}
          {validationResponse.organizationFailed.careTypes} in{' '}
          {validationResponse.organizationFailed.stateName}.
        </Text.body>
      )}
      {validationResponse?.providerFailed && (
        <Text.body kind="danger">
          We currently don&apos;t have any available {validationResponse.providerFailed.careTypes}{' '}
          providers who are licensed in {validationResponse.providerFailed.stateName}.
        </Text.body>
      )}
    </WidgetContainer>
  );
};

const MaxWidth = styled.div`
  max-width: 12.5rem;
`;
