/* eslint-disable no-case-declarations */
import { capitalize, lowerCase } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { Event, NextSteps, User } from '../../graphQL';
import { assessmentEventTitle } from '../../modelUtils/assessment';
import { getFullName } from '../../modelUtils/users';
import { Nullable } from '../../types';
import { titleCase } from '../../utils';
import { Icon, IconKey } from '../Icons';
import { CurrentProviderContext, useCurrentProvider } from '../Permissions';
import { transitionEventCopy } from './copy';
import { Styles, Text } from './styles';

type EventUser = Pick<User, 'id' | 'firstName' | 'lastName' | 'preferredName'>;
type EventType = Pick<Event, 'id' | 'tag' | 'createdAt' | 'data'> & { user?: EventUser | null };

interface Props {
  events: EventType[];
  onClickEvent: (event: ParsedEvent) => void;
  verbose?: boolean;
  user?: EventUser;
}

const checkCondition = (event: ParsedEvent, providerContext: CurrentProviderContext) => {
  return event.condition ? event.condition(providerContext) : true;
};

export const Timeline = ({ events, onClickEvent, verbose, user }: Props) => {
  const providerContext = useCurrentProvider();
  let parsedEvents: ParsedEvent[] = [];
  if (user) {
    const patientName = getFullName(user);
    parsedEvents = events
      .map(e => parseEvent(e, patientName))
      .filter(i => !!i && checkCondition(i, providerContext)) as ParsedEvent[];
  } else {
    parsedEvents = events
      .map(e => parseEvent(e))
      .filter(i => !!i && checkCondition(i, providerContext)) as ParsedEvent[];
  }

  if (parsedEvents.length === 0) {
    return <Text.body className="pt3 pb4">No events to display</Text.body>;
  }

  return (
    <div>
      {parsedEvents.map((event, idx) => (
        <TimelineItem
          key={idx}
          event={event}
          onClick={() => onClickEvent(event)}
          verbose={verbose}
        />
      ))}
    </div>
  );
};

interface ItemProps {
  event: ParsedEvent;
  onClick: () => void;
  verbose?: boolean;
}

export const TimelineItem = ({ event, onClick, verbose }: ItemProps) => {
  const { tag, createdAt, icon, text, verboseText } = event;
  const parsedTime = moment(createdAt);
  return (
    <Styles.listDiv hoverable onClick={onClick}>
      <Styles.infoDiv>
        <Text.caption kind="grayText">
          {parsedTime.format('MM/DD/YYYY')}
          <br />
          {parsedTime.format('hh:mm a')}
        </Text.caption>
      </Styles.infoDiv>
      <Icon icon={icon} alt={tag} className="pa2" />
      <Text.body>{(verbose && verboseText) ?? text}</Text.body>
    </Styles.listDiv>
  );
};

type ParsedEvent = EventType & {
  icon: IconKey;
  text: string;
  tab: string;
  verboseText?: string;
  condition?: (arg0: CurrentProviderContext) => boolean;
};
export const parseEvent = (event: EventType, hardcodedPatientName?: string): ParsedEvent | null => {
  const data: any = event.data ?? {};
  const providerName = data.name ?? 'Provider';
  const patientName = hardcodedPatientName ?? (event.user && getFullName(event.user)) ?? 'Patient';

  switch (event.tag) {
    case 'continuity-of-care:provider:confirm-sponsored-care':
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${providerName} confirmed continuation of sponsored ${data.careType} for upcoming term`,
        tab: 'messages',
        ...event,
      };
    case 'continuity-of-care:provider:reactivate-sponsored-care':
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${providerName} manually reactivated sponsored ${data.careType}`,
        tab: 'messages',
        ...event,
      };
    case 'continuity-of-care:user:self-reactivate-sponsored-care':
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${patientName} completed sponsored ${data.careType} self-reactivation form`,
        tab: 'messages',
        ...event,
      };
    case 'patient:message:sent':
      return {
        icon: 'iconsBlackMessageSvg',
        text: `${patientName} sent a message`,
        tab: 'messages',
        ...event,
      };
    case 'scales:complete:monthly':
      return {
        icon: 'iconsBlackMbcSvg',
        text: `${patientName} completed an assessment`,
        tab: 'assessments',
        ...event,
      };
    case 'scales:complete:initial':
      return {
        icon: 'iconsBlackMbcSvg',
        text: `${patientName} completed the intake assessment`,
        tab: 'assessments',
        ...event,
      };
    case 'patient:assessment:complete':
      // covered by 'scales:complete:monthly' & 'scales:complete:initial'
      if (data.key === 'scales' || data.key === 'intake') return null;

      return {
        icon: 'iconsBlackMbcSvg',
        text: `${patientName} completed ${assessmentEventTitle(data.key)}`,
        tab: 'assessments',
        ...event,
      };
    case 'patient:calendar:booked':
      return {
        icon: 'iconsBlackApptSvg',
        text: `${patientName} booked an appointment: ${appointmentDetail({
          start: data.startTime,
          ...data,
        })}`,
        tab: 'appointments',
        ...event,
      };
    case 'patient:appointment:rescheduled':
      return {
        icon: 'iconsBlackApptSvg',
        text: `${patientName} rescheduled an appointment: ${appointmentDetail(data)}`,
        tab: 'appointments',
        ...event,
      };
    case 'provider:appointment:rescheduled':
      return {
        icon: 'iconsBlackApptSvg',
        text: `${providerName} rescheduled an appointment for ${patientName}: ${appointmentDetail(
          data
        )}`,
        tab: 'appointments',
        ...event,
      };
    case 'patient:appointment:cancelled':
      return {
        icon: 'iconsBlackApptSvg',
        text: `${patientName} cancelled an appointment: ${appointmentDetail(data)}`,
        tab: 'appointments',
        ...event,
      };
    case 'provider:calendar:booked':
      return {
        icon: 'iconsBlackApptSvg',
        text: `${providerName} booked an appointment: ${appointmentDetail({
          start: data.startTime,
          ...data,
        })}`,
        verboseText: `${providerName} booked an appointment for ${patientName}: ${appointmentDetail(
          { start: data.startTime, ...data }
        )}`,
        tab: 'appointments',
        ...event,
      };
    case 'provider:appointment:cancelled':
      return {
        icon: 'iconsBlackApptSvg',
        text: `${providerName} cancelled an appointment: ${appointmentDetail(data)}`,
        verboseText: `${providerName} cancelled an appointment for ${patientName}: ${appointmentDetail(
          data
        )}`,
        tab: 'appointments',
        ...event,
      };
    case 'provider:appointment:no-show':
      return {
        icon: 'iconsBlackApptSvg',
        text: `${providerName} marked an appointment as no-show: ${appointmentDetail(data)}`,
        verboseText: `${providerName} marked ${patientName}'s appointment as no-show: ${appointmentDetail(
          data
        )}`,
        tab: 'appointments',
        ...event,
      };
    case 'provider:appointment:complete':
      return {
        icon: 'iconsBlackApptSvg',
        text: `${providerName} marked an appointment as complete: ${appointmentDetail(data)}`,
        verboseText: `${providerName} marked ${patientName}'s appointment as complete: ${appointmentDetail(
          data
        )}`,
        tab: 'appointments',
        ...event,
      };
    case 'medical-history':
      return {
        icon: 'iconsBlackMbcSvg',
        text: `${patientName} completed a medical history`,
        tab: 'notes',
        ...event,
      };
    case 'provider:note:complete':
      return {
        icon: 'iconsBlackNoteSvg',
        tab: 'notes',
        text: `${providerName} completed ${data.title ?? 'a note'}`,
        verboseText: `${providerName} completed ${data.title ?? 'a note'} for ${patientName}`,
        ...event,
      };
    case 'patient:personal-info:updated':
      return {
        icon: 'iconsUserSvg',
        text: `${patientName} updated their contact info`,
        tab: 'overview',
        ...event,
      };
    case 'provider:message:sent':
      return {
        icon: 'iconsBlackMessageSvg',
        text: `${providerName} sent a message`,
        verboseText: `${providerName} sent a message to ${patientName}`,
        tab: 'messages',
        ...event,
      };
    case 'provider:safety-plan:activate':
      return {
        icon: 'iconsBlackSafetySvg',
        text: `${providerName} activated the Safety Plan`,
        verboseText: `${providerName} activated ${patientName}'s Safety Plan`,
        tab: 'safety-plan',
        ...event,
      };
    case 'provider:safety-plan:edit':
      return {
        icon: 'iconsBlackSafetySvg',
        text: `${providerName} edited the Safety Plan`,
        verboseText: `${providerName} edited ${patientName}'s Safety Plan`,
        tab: 'safety-plan',
        ...event,
      };
    case 'patient:safety-plan:edit':
      // completed if went from incomplete to complete
      const completed = data.isComplete && !data.wasAlreadyComplete;

      return {
        icon: 'iconsBlackSafetySvg',
        text: `${patientName} ${completed ? 'completed' : 'edited'} their Safety Plan`,
        verboseText: `${patientName} ${completed ? 'completed' : 'edited'} their Safety Plan`,
        tab: 'safety-plan',
        ...event,
      };
    case 'provider:continuity:export': {
      return {
        icon: 'iconsBlackNoteSvg',
        text: `${providerName} exported a Continuity of Care Record`,
        verboseText: `${providerName} exported a Continuity of Care Record for ${patientName}`,
        tab: 'notes',
        ...event,
      };
    }
    case 'provider:note:export': {
      return {
        icon: 'iconsBlackNoteSvg',
        text: `${providerName} exported a ${data.key ?? 'medical'} note`,
        verboseText: `${providerName} exported ${data.key ?? 'medical'} note for ${patientName}`,
        tab: 'notes',
        ...event,
      };
    }
    case 'provider:care-team:edited': {
      const removedNames =
        data.removedNames.length > 0
          ? data.removedNames
              ?.map((removedTeamMember: any) => removedTeamMember?.name ?? '')
              .join(', ')
          : '';
      const removedText =
        data.removedNames.length > 0 ? `removed ${removedNames} from campus team` : '';
      const addedNames =
        data.addedNames.length > 0
          ? data.addedNames
              ?.map((addedTeamMember: any) =>
                addedTeamMember?.relationshipType
                  ? `${addedTeamMember?.name} as '${lowerCase(addedTeamMember.relationshipType)}'`
                  : addedTeamMember?.name
              )
              .join(', ')
          : '';
      const addedText = data.addedNames.length > 0 ? `added ${addedNames} to campus team` : '';
      const changedNames =
        data.changedNames?.length > 0
          ? data.changedNames
              ?.map((changedTeamMember: any) =>
                changedTeamMember?.relationshipType
                  ? `${changedTeamMember?.name} to '${lowerCase(
                      changedTeamMember.relationshipType
                    )}'`
                  : changedTeamMember?.name
              )
              ?.join(', ')
          : '';
      const changedText = data.changedNames?.length > 0 ? `changed ${changedNames}` : '';
      const returnedText = () => {
        if (removedText && addedText && changedText) {
          return `${providerName} ${removedText}, ${addedText}, and ${changedText}.`;
        }
        if (removedText && addedText && !changedText) {
          return `${providerName} ${removedText} and ${addedText}`;
        }
        if (removedText && !addedText && changedText) {
          return `${providerName} ${removedText} and ${changedText}`;
        }
        if (!removedText && addedText && changedText) {
          return `${providerName} ${addedText} and ${changedText}`;
        }
        return `${providerName} ${removedText}${addedText}${changedText}`;
      };
      const text = returnedText();
      return {
        icon: 'iconsBlackHeartSvg',
        text,
        verboseText: `${text} for ${patientName}`,
        tab: 'overview',
        ...event,
      };
    }
    case 'provider:upload:completed': {
      return {
        icon: 'iconsBlackUploadSvg',
        text: `${providerName} uploaded ${data.filename}`,
        verboseText: `${providerName} uploaded ${data.filename} to ${patientName}'s profile`,
        tab: 'files',
        ...event,
      };
    }
    case 'provider:student:created': {
      return {
        icon: 'iconsBlackHeartSvg',
        text: `${providerName} referred ${patientName} to Mantra`,
        tab: 'overview',
        ...event,
      };
    }
    case 'provider:upload:deleted': {
      return {
        icon: 'iconsBlackUploadSvg',
        text: `${providerName} deleted ${data.filename}`,
        verboseText: `${providerName} deleted ${data.filename} from ${patientName}'s profile`,
        tab: 'files',
        ...event,
      };
    }
    case 'provider:upload:permissions-changed': {
      const permissions = `${
        data.visibleToExternal ? 'Visible' : 'Not visible'
      } to external care team members`;
      return {
        icon: 'iconsBlackUploadSvg',
        text: `${providerName} updated permissions of ${data.filename}: ${permissions}`,
        verboseText: `${providerName} updated permissions of ${data.filename} on ${patientName}'s profile: ${permissions}`,
        tab: 'files',
        ...event,
      };
    }
    case 'provider:next-steps:created': {
      const nextStep = transitionEventCopy[(data?.nextStep ?? '') as NextSteps];
      if (!nextStep) return null;
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${providerName} marked ${data.careType} next steps as ${nextStep}`,
        tab: 'overview',
        ...event,
      };
    }
    case 'provider:care-flow:added': {
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${providerName} added a ${data.careType} plan`,
        tab: 'overview',
        ...event,
      };
    }
    case 'provider:care-flow:removed': {
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${providerName} removed the ${data.careType} plan`,
        tab: 'overview',
        ...event,
      };
    }
    case 'system:care-status:updated':
    case 'provider:care-status:updated': {
      // this wont exist for pre-careFlowV2 stuff
      const ctText = data.careType ? ` ${data.careType} ` : ' ';
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${data.name ?? 'System'} changed${ctText}status from ${titleCase(
          data.from
        )} to ${titleCase(data.to)}`,
        tab: 'overview',
        ...event,
      };
    }
    case 'system:payment-source:updated':
    case 'provider:payment-source:updated': {
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `Payment Source (${data.careType}) was updated from ${data.from} to ${data.to}`,
        tab: 'overview',
        ...event,
      };
    }
    case 'patient:continued-care-task:completed': {
      return {
        icon: 'iconsCheckBlackSvg',
        text: 'Patient completed Continued Care task.',
        tab: 'overview',
        ...event,
      };
    }
    case 'provider:patient-pii:updated': {
      if (data.kind === 'name') {
        const { first, last } = data;
        const getText = () => {
          if (first && last) {
            return `${data.name} changed Legal Full Name from "${first.from} ${last.from}" to "${first.to} ${last.to}"`;
          }
          if (first) {
            return `${data.name} changed First Name from "${first.from}" to "${first.to}"`;
          }
          return `${data.name} changed Last Name from "${last.from}" to "${last.to}"`;
        };
        return {
          icon: 'iconsBlackEditSvg',
          text: getText(),
          tab: 'overview',
          ...event,
        };
      }
      if (data.kind === 'dob') {
        return {
          icon: 'iconsBlackEditSvg',
          text: `${data.name} changed birthdate from ${data.from} to ${data.to}`,
          tab: 'overview',
          ...event,
        };
      }
      return null;
    }
    case 'patient:checkedin': {
      return {
        icon: 'iconsBlackApptSvg',
        text: `${patientName} checked in for an appointment: ${appointmentDetail(data)}`,
        tab: 'appointments',
        ...event,
      };
    }
    case 'provider:data-sharing:requested': {
      return {
        icon: 'iconsBlackQuestionMarkSvg',
        text: `${data.name} requested full access to patient record`,
        tab: 'overview',
        ...event,
      };
    }
    case 'patient:data-sharing:consented': {
      return {
        icon: 'iconsBlackCheckSvg',
        text: `${patientName} temporarily shared their full record with their campus collaboration team. Access expires ${moment(
          data.endDate
        ).format('M/D/YYYY')}`,
        tab: 'overview',
        ...event,
      };
    }
    case 'patient:data-sharing:declined': {
      return {
        icon: 'iconsBlackXSvg',
        text: `${patientName} declined request for access to full record.`,
        tab: 'overview',
        ...event,
      };
    }
    case 'patient:data-sharing:revoked': {
      return {
        icon: 'iconsBlackCheckSvg',
        text: `${patientName} revoked access to full record`,
        tab: 'overview',
        ...event,
      };
    }
    case 'patient:data-sharing:expired': {
      return {
        icon: 'iconsBlackCheckSvg',
        text: `Full record access expired`,
        verboseText: `${patientName} full record access expired`,
        tab: 'overview',
        ...event,
      };
    }
    case 'provider:assessment:pushed':
      return {
        icon: 'iconsBlackQuestionMarkSvg',
        text: `${data.name ?? 'A provider'} sent ${assessmentEventTitle(
          data.key
        )} to ${patientName}`,
        tab: 'assessments',
        ...event,
      };
    case 'provider:scales:pushed':
      return {
        icon: 'iconsBlackQuestionMarkSvg',
        text: `${data.name ?? 'A provider'} pushed Scales to ${patientName}`,
        tab: 'assessments',
        ...event,
      };
    case 'provider:outreach:attempted':
      return {
        icon: 'iconsBlackMessageSvg',
        text: `Outreach attempt made: ${data.subject}`,
        tab: 'outreach',
        ...event,
      };
    case 'provider:re-enrollment:created':
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${
          data.name ?? 'A provider'
        } sent sponsored care re-enrollment task to ${patientName}.`,
        tab: 'overview',
        ...event,
      };
    case 'provider:re-enrollment:cancelled':
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${data.name ?? 'A provider'} cancelled sponsored care re-enrollment task.`,
        verboseText: `${
          data.name ?? 'A provider'
        } cancelled sponsored care re-enrollment task for ${patientName}.`,
        tab: 'overview',
        ...event,
      };
    case 'patient:re-enrollment:confirmed':
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${patientName} confirmed re-enrollment in sponsored care.`,
        tab: 'overview',
        ...event,
      };

    case 'provider:suggested-providers:added':
      return {
        icon: 'iconsBlackStarSvg',
        text: `${data.name} selected suggested provider(s): ${(
          data.suggestedProviderNames ?? []
        ).join(', ')}`,
        tab: 'overview',
        ...event,
      };

    case 'patient:re-enrollment:declined':
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${patientName} declined re-enrollment in sponsored care.`,
        tab: 'overview',
        ...event,
      };
    case 'patient:zendesk-ticket:added':
      return {
        icon: 'iconsBlackMessageSvg',
        text: `${patientName} sent an email to Patient Support`,
        tab: 'overview',
        ...event,
      };
    case 'provider:patient-email:updated':
      return {
        icon: 'iconsBlackEditSvg',
        text: `${providerName} updated the patient's email`,
        tab: 'overview',
        ...event,
      };
    case 'provider:patient-name:updated':
      return {
        icon: 'iconsBlackEditSvg',
        text: `${providerName} updated the patient's name`,
        tab: 'overview',
        ...event,
      };
    case 'provider:patient-primaryAddress:updated':
      return {
        icon: 'iconsBlackEditSvg',
        text: `${providerName} updated the patient's primary address`,
        tab: 'overview',
        ...event,
      };
    case 'provider:patient-billingAddress:updated':
      return {
        icon: 'iconsBlackEditSvg',
        text: `${providerName} updated the patient's primary address`,
        tab: 'overview',
        ...event,
      };
    case 'provider:phone:updated':
      return {
        icon: 'iconsBlackEditSvg',
        text: `${providerName} updated the patient's phone number`,
        tab: 'overview',
        ...event,
      };
    case 'provider:patient-dob:updated':
      return {
        icon: 'iconsBlackEditSvg',
        text: `${providerName} updated the patient's date of birth`,
        tab: 'overview',
        ...event,
      };
    case 'provider:preferredName:updated':
      return {
        icon: 'iconsBlackEditSvg',
        text: `${providerName} updated the patient's preferred name`,
        tab: 'overview',
        ...event,
      };
    case 'provider:secondaryState:updated':
      return {
        icon: 'iconsBlackEditSvg',
        text: `${providerName} updated the patient's secondary state`,
        tab: 'overview',
        ...event,
      };
    case 'coc-eligibility-form:submitted':
      return {
        icon: 'iconsBlackCheckSvg',
        text: `${providerName} submitted a ${data.careType} eligibility form for ${patientName}`,
        tab: 'notes',
        ...event,
      };
    case 'provider:ticket:created':
      return {
        icon: 'iconsBlackToolsSvg',
        text: `${providerName} created task: ${data.title}`,
        tab: 'overview',
        ...event,
      };
    case 'provider:ticket:status-changed':
      let statusChangeActionText: Nullable<string> = null;
      if (data.to === 'complete') {
        statusChangeActionText = 'resolved task';
      } else if (data.to === 'deleted') {
        statusChangeActionText = 'deleted task';
      } else if (data.to === 'blocked' && data.from !== 'blocked') {
        statusChangeActionText = 'marked task as blocked';
      } else if (data.from === 'blocked' && data.to !== 'blocked') {
        statusChangeActionText = 'marked task as unblocked';
      }
      return statusChangeActionText
        ? {
            icon: 'iconsBlackToolsSvg',
            text: `${providerName} ${statusChangeActionText}: ${data.title}`,
            tab: 'overview',
            ...event,
          }
        : null;
    case 'provider:medication-plan:initiated': {
      return {
        icon: 'iconsBlackRxSvg',
        text: 'Patient medication plan initiated',
        tab: 'notes',
        ...event,
      };
    }
    case 'patient:risk-level:changed': {
      const { noteId, before, after } = data;
      // not due to a note
      if (!noteId || !after || before === after) return null;

      // there is an edge case in which a provider (example: mantra-admin) could sumbit a risk change note without the patient's initial risk being set.
      // If that happens the event's wording will be slightly different
      if (!before) {
        return {
          icon: 'iconsBlackChangeStatusSvg',
          text: `${
            providerName ? `${providerName} updated patient risk ` : 'Patient risk updated '
          } to ${capitalize(after)}`,
          tab: 'notes',
          ...event,
        };
      }
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${
          providerName ? `${providerName} updated patient risk ` : 'Patient risk updated '
        } from ${capitalize(before)} to ${capitalize(after)}`,
        tab: 'notes',
        ...event,
      };
    }
    case 'patient:hub:onboarded': {
      return {
        icon: 'iconsUserSvg',
        text: `${patientName} activated Mantra Care Hub.`,
        tab: 'overview',
        ...event,
      };
    }
    case 'patient:triage:status': {
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `System marked Self-Refer Triage status as 'Under Clinical Review'.`,
        tab: 'notes',
        ...event,
      };
    }
    case 'provider:triage:status-changed': {
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${providerName} marked Self-Refer Triage status as 'Fit for Care'`,
        tab: 'overview',
        ...event,
      };
    }
    case 'patient:triage-flow:complete': {
      return {
        icon: 'iconsUserSvg',
        text: `${patientName} completed Self Refer Screening 1`,
        tab: 'notes',
        ...event,
      };
    }
    case 'patient:coaching:goals-assessment-completed': {
      return {
        icon: 'iconsBlackMbcSvg',
        text: `${patientName} completed Coaching Goals Assessment`,
        tab: 'assessments',
        ...event,
      };
    }
    case 'patient:coaching:wellness-assessment-completed': {
      return {
        icon: 'iconsBlackMbcSvg',
        text: `${patientName} completed Coaching Wellness Assessment`,
        tab: 'assessments',
        ...event,
      };
    }
    case 'patient:coaching:status-changed': {
      const text = `Coaching status ${
        data.from ? `changed from ${titleCase(data.from)} to` : 'set to'
      } ${titleCase(data.to)}`;
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text,
        tab: 'overview',
        ...event,
      };
    }
    case 'patient:coaching:coach-assigned': {
      return {
        icon: 'iconsCheckBlackSvg',
        text: `${providerName} assigned as Coach to ${patientName}`,
        tab: 'overview',
        ...event,
      };
    }
    case 'provider:patient-password:updated': {
      return {
        icon: 'iconsUserSvg',
        text: `${providerName} generated a new password for ${patientName} `,
        tab: 'overview',
        ...event,
      };
    }
    case 'patient:partner-charlie-referral': {
      return {
        icon: 'iconsBlackPlusSvg',
        text: `${providerName} initiated IOP (Charlie Health) referral`,
        tab: 'overview',
        ...event,
      };
    }
    case 'patient:partner-togetherall-referral': {
      return {
        icon: 'iconsBlackPlusSvg',
        text: `${providerName} initiated Togetherall referral`,
        tab: 'overview',
        ...event,
      };
    }
    default:
      return null;
  }
};

function appointmentDetail(data: { start: string; description?: string }) {
  const m = moment(data.start).tz(moment.tz.guess());
  return `${data.description ?? 'Appointment'} (${m.format('M/D/YYYY')} at ${m.format(
    'h:mm a z'
  )})`;
}
