import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { nextStepsModuleCopy } from '../../../Components/ContinuityOfCare/EligibilityCopy';
import { useCocEligibility } from '../../../Components/ContinuityOfCare/hooks/useCocEligibility';
import { useSessionsEnding } from '../../../Components/ContinuityOfCare/hooks/useSessionsEnding';
import { FinalButton } from '../../../Components/FinalButton';
import { Icon } from '../../../Components/Icons';
import { ColorLookup, colorMap, colors, Text } from '../../../globalStyles';
import { CareType, NextSteps } from '../../../graphQL';
import { useDrilldownContext } from '../helpers';
import { COCModal as COCModalV2 } from '../Sidebar/ContinuityOfCareV2/COCModal';

interface EligibilityModuleProps {
  careType: CareType;
}

export const ContinuityOfCareEligibility = ({ careType }: EligibilityModuleProps) => {
  const sessionsEnding = useSessionsEnding({ careType });
  const { user } = useDrilldownContext();
  const [showNextStepsModal, setShowNextStepsModal] = useState(false);
  const eligibilityFormUrl = 'https://mantrahealth.typeform.com/to/n7xyGIKw';

  const { currentCarePeriod, nextCarePeriod, showEligibilityModule } = useCocEligibility({
    careType,
  });

  // User should not see the component if they're not currently in a care period.
  if (!currentCarePeriod) return null;

  const { startDate, endDate } = currentCarePeriod;
  const nextSteps = user.continuityOfCareTransitions?.filter(
    i =>
      i.careType === careType &&
      i.updatedAt &&
      moment(i.updatedAt).isBetween(startDate, endDate, 'days', '[]')
  );

  if (
    !user?.organization ||
    !currentCarePeriod ||
    !showEligibilityModule ||
    !user?.careTypes.includes(careType)
  ) {
    return null;
  }

  const careFlow = user.careFlows.find(i => i.careType === careType);
  const copyCategory = sessionsEnding
    ? nextStepsModuleCopy.lessThan3ApptsLeft
    : nextStepsModuleCopy.moreThan2ApptsLeft;

  let copy = null;
  let isNextStepsSelected = false;

  if (nextSteps && nextSteps?.length) {
    const nextStep = nextSteps[0].nextSteps as NextSteps;
    copy = copyCategory[nextStep];
    isNextStepsSelected = true;
  } else {
    copy = copyCategory.NotSelected;
  }

  return (
    <Wrapper>
      <div>
        <Text.h3 className="mb3">
          Next steps for upcoming {careType.toLowerCase()} care period
        </Text.h3>
        {sessionsEnding && (
          <Text.bodySmall className="mb3" style={{ fontWeight: 500 }}>
            {careFlow?.careSessionInfo?.remainingAppts} sponsored {careType.toLowerCase()} session
            {careFlow?.careSessionInfo?.remainingAppts !== 1 ? 's ' : ' '}
            remaining
          </Text.bodySmall>
        )}
        <Text.bodySmall style={{ fontWeight: 500 }}>
          Sponsored {currentCarePeriod?.periodType === 'sponsoredBreak' ? 'break' : 'term'} ends on{' '}
          {moment(currentCarePeriod?.endDate).format('MM/DD/YY')}
        </Text.bodySmall>
      </div>
      <GreyBox>
        <div className="mb2 flex justify-between items-center">
          <Text.label kind="black">{careType} next steps</Text.label>
          <div className="flex">
            <Text.bodyBold kind={copy.textColor ? (copy.textColor as ColorLookup) : 'text'}>
              {copy.label}{' '}
            </Text.bodyBold>
            <FinalButton
              kind="minimal_black"
              type="button"
              size="tiny"
              onClick={() => setShowNextStepsModal(true)}
              aria-label="Edit next steps"
            >
              <Icon icon="iconsBlackEditSvg" alt="Pencil icon" width={12} />
            </FinalButton>
          </div>
        </div>
        <div>
          <Text.bodySmall>
            {copy.description({
              nextStartDate: moment(nextCarePeriod?.startDate).format('MM/DD/YY'),
              endDate: moment(currentCarePeriod?.endDate).format('MM/DD/YY'),
            })}
          </Text.bodySmall>
        </div>
        {isNextStepsSelected ? (
          <div
            className="flex items-center w-100 mt3 pt2"
            style={{ borderTop: `1px solid ${colors.grey.lightBorder}` }}
          >
            <Icon icon="iconsExternalLinkSvg" className="mr2" alt="Link" style={{ opacity: 0.5 }} />
            <Text.bodySmallBold
              kind="grayText"
              className="pointer"
              onClick={() => window.open(eligibilityFormUrl, '_blank')}
            >
              Eligibility Form
            </Text.bodySmallBold>
          </div>
        ) : (
          <FinalButton
            className="mt3 w-100"
            kind="primary"
            onClick={() => window.open(eligibilityFormUrl, '_blank')}
          >
            Open Eligibility Form
          </FinalButton>
        )}
      </GreyBox>

      {showNextStepsModal && (
        <COCModalV2
          careType={careType}
          careEnd={moment(currentCarePeriod?.endDate)}
          onClose={() => setShowNextStepsModal(false)}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey.lightBorder};
  border-left: 10px solid ${colorMap.warning[0]};
  width: 100%;
  padding: 24px 20px 24px 28px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: minmax(0, 3fr) minmax(0, 5fr);
  grid-gap: 40px;
  margin-bottom: 1rem;
`;

const GreyBox = styled.div`
  background-color: ${colors.grey.light};
  border-radius: 4px;
  padding: 24px 20px 20px 20px;
`;
