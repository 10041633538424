import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as BaseApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/link-error';
import React from 'react';
import { getAuthToken } from './token';
import { isTokenExpired, logout } from './utils';

const cache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  credentials: 'include',
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getAuthToken();
  if (isTokenExpired(token)) logout();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  return forward(operation);
});

const errorHandler = onError(({ graphQLErrors }) => {
  graphQLErrors?.forEach(gqlError => {
    const statusCode = gqlError?.extensions?.exception?.httpStatus;
    if (statusCode === 401) {
      logout();
      window.location.reload();
    }
  });
});

export const apolloClient = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  cache,
  // @ts-ignore
  link: authMiddleware.concat(errorHandler).concat(httpLink),
  defaultOptions: {
    watchQuery: { fetchPolicy: 'no-cache' },
    query: { fetchPolicy: 'no-cache' },
  },
});

export const ApolloProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <BaseApolloProvider client={apolloClient}>{children}</BaseApolloProvider>
);
