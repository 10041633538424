import { partition } from 'lodash';
import React from 'react';
import { useGetSkillsAssignedToUserQuery } from '../../../graphQL';
import { getStylesheet } from '../../../styles';
import { usePatientContext } from '../../contexts/patientContext';
import { Heading, Layout, Skeleton, Text } from '../../core';
import { PageContent, PageLoading, PagePatientHeader } from '../pageCore';
import { AssignNewSkill } from './components/AssignNewSkill';
import { CompletedSkills } from './components/CompletedSkills';
import { GrayBox } from './components/GrayBox';
import { NewSkills } from './components/NewSkills';

export const PatientSkillsManagePage = (): JSX.Element => {
  const { patientId, patient, patientLoading } = usePatientContext();

  const {
    data: skillAssignmentsData,
    loading: skillAssignmentsLoading,
    error: skillAssignmentsError,
    refetch: skillAssignmentsRefetch,
  } = useGetSkillsAssignedToUserQuery({
    variables: {
      page: 1,
      perPage: 100,
      userId: patientId,
    },
  });

  if (patientLoading) {
    return <PageLoading pageName="patient skills manage" />;
  }

  if (skillAssignmentsLoading) {
    return <Skeleton h={110} />;
  }

  const assignedSkills = skillAssignmentsData?.getSkillsAssignedToUser.skillAssignments ?? [];
  const [completedSkills, newSkills] = partition(assignedSkills, 'completedAt');

  const patientName = patient?.name ?? 'Unknown Patient';

  return (
    <PageContent testID="patient-skills-manage-page">
      <PagePatientHeader />

      <Layout.VStack {...styles.topStack} space={8}>
        <Layout.VStack space={4}>
          <Layout.HStack {...styles.assignHeader}>
            <Heading.h4>Skill Assignments</Heading.h4>

            <AssignNewSkill patientId={patientId} refetchAssignments={skillAssignmentsRefetch} />
          </Layout.HStack>

          {!skillAssignmentsLoading && !skillAssignmentsError && (
            <NewSkills
              patientId={patientId}
              patientName={patientName}
              refetchAssignments={skillAssignmentsRefetch}
              skillAssignments={newSkills}
            />
          )}
        </Layout.VStack>

        <Layout.VStack space={4}>
          <Heading.h4>{patientName}’s activities</Heading.h4>

          {skillAssignmentsLoading && <Skeleton h={110} />}

          {skillAssignmentsError !== undefined && !skillAssignmentsLoading && (
            <GrayBox>
              <Text.para>Error: Unable to load the patient's skills.</Text.para>
            </GrayBox>
          )}

          {!skillAssignmentsLoading && (
            <CompletedSkills skillAssignments={completedSkills} patientName={patientName} />
          )}
        </Layout.VStack>
      </Layout.VStack>
    </PageContent>
  );
};

const styles = getStylesheet({
  topStack: {
    alignItems: 'stretch',
    display: 'flex',
    paddingBottom: 24,
    width: '100%',
  },

  assignHeader: {
    justifyContent: 'space-between',

    // @ts-expect-error - Gap works but native base doesn't know that yet.
    gap: 1,
  },
});
