import { pick, last, minBy } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';
import {
  DetailedAvailability,
  SelectAvailabilityFn,
} from '../../Components/ProviderNetwork/Availability/DetailedAvailability';
import { ForBookingUser } from '../../Components/ProviderNetwork/Availability/types';
import { useDefaultCareTypeParam } from '../../Components/ProviderNetwork/hooks/useDefaultParams';
import {
  NetworkProviders,
  ProviderNetworkContextProvider,
  useProviderNetworkContext,
} from '../../Components/ProviderNetwork/ProviderNetworkContext';
import {
  ProviderNetworkRow,
  RenderAvailability,
} from '../../Components/ProviderNetwork/ProviderNetworkRow';
import { ProviderNetworkShell } from '../../Components/ProviderNetwork/ProviderNetworkShell';
import { ProvidersNotFound } from '../../Components/ProviderNetwork/ProvidersNotFound';
import { Nullable } from '../../types';
import {
  AppointmentTemplateFragment,
  Entitlement,
  StateCodes,
  useOrganizationEntitlementsQuery,
} from '../../graphQL';
import { useMoveToFirstProviderNetworkAvailability } from '../../Hooks/useMoveToFirstProviderNetworkAvailability';
import { AppointmentType } from './types';

type AppointmentTemplate = Pick<
  AppointmentTemplateFragment,
  'duration' | 'description' | 'appointmentType' | 'careType'
>;

type AvailabilityProps = {
  user: ForBookingUser;
  appointmentTemplate: AppointmentTemplate;
  onSelectAvailability: SelectAvailabilityFn;
  onBack: () => void;
};

export const ProviderAvailabilityList = ({
  user,
  appointmentTemplate,
  onBack,
  onSelectAvailability,
}: AvailabilityProps) => {
  const { careType: appointmentCareType, appointmentType } = appointmentTemplate;
  const careType = useDefaultCareTypeParam(appointmentCareType);
  return (
    <ProviderNetworkContextProvider withDateRange searchBy={{ user, careType, appointmentType }}>
      <ProviderNetworkShell>
        <Body
          onBack={onBack}
          appointmentTemplate={appointmentTemplate}
          user={user}
          onSelectAvailability={onSelectAvailability}
        />
      </ProviderNetworkShell>
    </ProviderNetworkContextProvider>
  );
};

export const nextAvailableIntake = (
  providers: NetworkProviders,
  dgmActive?: boolean,
  days?: Nullable<Moment[]>
) => {
  return dgmActive
    ? minBy(
        providers
          .flatMap(p => p.upcomingAvailability)
          .map(v => moment(v.start))
          .filter(v => v.isAfter(last(days))) // may need to change this later, could be connected to [19909]
          .map(v => v.toDate()),
        v => v.valueOf()
      )
    : undefined;
};

type BodyProps = {
  user: ForBookingUser;
  appointmentTemplate: AppointmentTemplate;
  onSelectAvailability: SelectAvailabilityFn;
  onBack: () => void;
};

const Body = ({ user, appointmentTemplate, onSelectAvailability, onBack }: BodyProps) => {
  const { duration, appointmentType } = appointmentTemplate;
  const { providers, startDate, dedicatedGroupModelActive, days, searchVariables } =
    useProviderNetworkContext();

  const organizationId = user.organization?.id;
  const { data: entitlementData } = useOrganizationEntitlementsQuery({
    variables: { id: organizationId! },
    skip: !organizationId,
  });
  const wholeCampusCare = !!entitlementData?.organization.entitlements.some(
    ent => ent.key === Entitlement.WholeCampusCare
  );

  const ProviderAvailability: RenderAvailability = useMemo(
    () => props =>
      (
        <DetailedAvailability
          provider={pick(props.provider, ['id', 'name', 'careTypes'])}
          nextAvailability={
            // upcomingIntakeAvailability has the appointment times sorted on the backend.
            props.provider.upcomingAvailability.filter(a => moment(a.start).isAfter(startDate))[0]
          }
          forBooking={{
            user,
            duration,
            appointmentType: appointmentType as AppointmentType,
          }}
          patientState={
            searchVariables.state ||
            (user.primaryAddressState as StateCodes | undefined) ||
            undefined
          }
          onSelectAvailability={onSelectAvailability}
          forWholeCampusCareOrg={wholeCampusCare}
        />
      ),
    [user, duration, onSelectAvailability, wholeCampusCare]
  );

  useMoveToFirstProviderNetworkAvailability();

  const suggestedProviderLookup = useMemo(
    () => new Set(user.suggestedProviders?.map(p => p.id) ?? []),
    [user]
  );

  // percolate suggested providers to top and keep providers sorted by upcoming appt
  const providersWithSuggestedAtTop = useMemo(() => {
    let i = 0;
    return providers.reduce((acc, p) => {
      if (suggestedProviderLookup.has(p.id)) {
        const percolated = [...acc.slice(0, i), p, ...acc.slice(i)];
        i += 1;
        return percolated;
      }
      return [...acc, p];
    }, [] as typeof providers);
  }, [providers, suggestedProviderLookup]);

  const nextAvailableProviderDate = nextAvailableIntake(
    providersWithSuggestedAtTop,
    dedicatedGroupModelActive,
    days
  );

  return (
    <>
      {providersWithSuggestedAtTop.map(p => (
        <ProviderNetworkRow
          key={p.id}
          provider={p}
          renderAvailability={ProviderAvailability}
          providerTopLabel={suggestedProviderLookup.has(p.id) ? 'Suggested Provider' : undefined}
        />
      ))}
      {!providersWithSuggestedAtTop.length && (
        <ProvidersNotFound
          nextAvailableProviderDate={nextAvailableProviderDate}
          onBack={onBack}
          providerAvailabilityHelp="email"
        />
      )}
    </>
  );
};
