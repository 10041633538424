import React from 'react';
import { Modal, ModalProps } from '../../../Components/Modal/Modal';
import { Notification } from '../../../Components/Notification';
import * as MantraModal from '../../../globalStyles/modal';
import { Text } from '../styles';
import { WarningWithIcon } from '../../../Components/Warning';
import { FinalButton } from '../../../Components/FinalButton';
import { MantraAdminOnly, useCurrentProvider } from '../../../Components/Permissions';
import { useDrilldownContext } from '../helpers';
import {
  PartnerReferralType,
  ReferralSource,
  useCreatePartnerReferralMutation,
} from '../../../graphQL';
import moment from 'moment-timezone';

type IOPModalProps = Omit<ModalProps, 'size' | 'children'> & {
  onClose: () => void;
};

export const IOPModal = ({ onClose, ...props }: IOPModalProps) => {
  const { currentProvider } = useCurrentProvider();
  const { user } = useDrilldownContext();
  const [createPartnerReferral, { error, loading }] = useCreatePartnerReferralMutation();
  function isBirthdateEligibleForCharlieHealth(birthdate: Date): boolean {
    const age = moment().diff(moment(birthdate), 'years');
    return age >= 18 && age <= 33;
  }
  const birthdayError = !user.birthDate;
  const ineligibleBirthdayError = (user.birthDate &&
    !isBirthdateEligibleForCharlieHealth(user.birthDate)) as boolean;

  const handleOnClick = async () => {
    if (birthdayError) {
      return;
    }
    const iopCreated = await createPartnerReferral({
      variables: {
        data: {
          userId: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          birthDate: user.birthDate!.toString(),
          preferredName: user.preferredName,
          providerId: currentProvider.id,
          referralSource: ReferralSource.Oz,
          partner: PartnerReferralType.CharlieHealth,
        },
      },
    });
    if (iopCreated) {
      window.open('https://www.charliehealth.com/mantra');
      onClose();
    }
  };
  return (
    <MantraAdminOnly>
      <Modal size="small" onClose={onClose} {...props}>
        {error && <Notification kind="negative">{error.message}</Notification>}
        {birthdayError && (
          <Notification kind="negative">
            {
              'User does not have a birthday. Please enter a birthday for the user profile. User must be over 18.'
            }
          </Notification>
        )}
        {ineligibleBirthdayError && (
          <Notification kind="negative">
            {'User has an ineligible birthday. User must be over 18 and under 34 to be eligible.'}
          </Notification>
        )}
        <Text.h2 className="mb4">
          Are you sure you want to refer this patient to IOP (Charlie Health)?
        </Text.h2>

        <div className="mb4">
          <div className="flex flex-column gap-2">
            <Text.body>
              Clicking the button below will
              <strong> add a referral event to this patient account timeline </strong>
              before the referral is completed.
            </Text.body>
            <WarningWithIcon>
              <Text.bodySmallBold>
                <u>Note</u>: You will be sent to Charlie Health’s referral form, which must be
                submitted to in order to successfully complete the IOP referral.
              </Text.bodySmallBold>
            </WarningWithIcon>
            <FinalButton
              onClick={handleOnClick}
              kind="primary"
              loading={loading}
              disabled={birthdayError || ineligibleBirthdayError}
            >
              Yes, Continue to IOP Referral Form
            </FinalButton>
          </div>
        </div>
        <MantraModal.closeLink className="b" onClick={onClose}>
          Cancel
        </MantraModal.closeLink>
      </Modal>
    </MantraAdminOnly>
  );
};
