import { useCurrentProvider } from '../../Components/Permissions';
import { AppView, Entitlement, Permission } from '../../graphQL';

export const useShowOnDemandCallReports = () => {
  const { currentProvider, appView, hasPermission } = useCurrentProvider();
  return (
    hasPermission(Permission.MantraAdmin) ||
    (appView === AppView.Mcp &&
      currentProvider.organizations[0].entitlements.some(({ key }) => key === Entitlement.OnDemand))
  );
};
