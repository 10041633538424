import { reduce } from 'lodash';
import { useToast } from 'native-base';
import React, { useState } from 'react';
import {
  GetAssignableSkillsQuery,
  useAssignSkillToUserMutation,
  useGetAssignableSkillsQuery,
} from '../../../../graphQL';
import { Button, Layout, Select } from '../../../core';

type AssignNewSkillProps = {
  patientId: number;
  refetchAssignments: () => void;
};

const selectKeyDelimiter = '<>';
const createSelectKey = (moduleId: string, skillId: string): string =>
  [moduleId, skillId].join(selectKeyDelimiter);
const parseSelectKey = (selectKey: string): { moduleId: string; skillId: string } => {
  const [moduleId, skillId] = selectKey.split(selectKeyDelimiter);
  return { moduleId, skillId };
};

export const AssignNewSkill = ({
  patientId,
  refetchAssignments,
}: AssignNewSkillProps): JSX.Element => {
  const toast = useToast();
  const { data: assignableSkillsData } = useGetAssignableSkillsQuery();

  const skillModuleOptions = reduce<
    GetAssignableSkillsQuery['getAssignableSkills'][number],
    Record<string, string>
  >(
    assignableSkillsData?.getAssignableSkills ?? [],
    (acc, assignableSkill) => {
      const id = createSelectKey(assignableSkill.moduleId, assignableSkill.skillId);
      acc[id] = assignableSkill.title;
      return acc;
    },
    {}
  );

  const [selectedItem, setSelectedItem] = useState<string>('');

  const [assignSkill] = useAssignSkillToUserMutation({
    onError: () => {
      toast.show({
        description: 'Skill could not be assigned.',
        duration: 3000,
      });
    },
    onCompleted: ({ assignSkillToUser: { isDuplicate, wasAssigned } }) => {
      void refetchAssignments();

      if (isDuplicate) {
        toast.show({
          description: 'Skill already assigned.',
          duration: 3000,
        });
      } else if (wasAssigned) {
        toast.show({
          description: 'Skill assigned successfully.',
          duration: 3000,
        });
      } else {
        toast.show({
          description: 'Skill could not be assigned.',
          duration: 3000,
        });
      }
    },
  });

  const onSkillSelected = (skillAndModuleId: string): void => {
    setSelectedItem(skillAndModuleId);
  };
  const onAssignPress = (): void => {
    if (selectedItem === undefined || selectedItem === '') {
      return;
    }

    const { moduleId, skillId } = parseSelectKey(selectedItem);

    void assignSkill({
      variables: {
        skillId,
        moduleId,
        userId: patientId,
      },
    });

    setSelectedItem('');
  };

  return (
    <Layout.HStack space={2}>
      <Select
        options={skillModuleOptions}
        selectedValue={selectedItem}
        defaultValue=""
        placeholder="Select Skill"
        onValueChange={onSkillSelected}
      />

      <Button.primarySmall
        isDisabled={selectedItem === '' || selectedItem === undefined}
        testID="button-skill-assignments-assign-new-skill"
        onPress={onAssignPress}
      >
        Assign
      </Button.primarySmall>
    </Layout.HStack>
  );
};
