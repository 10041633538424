import React from 'react';
import { BrowserRouter as Router, Redirect, useLocation } from 'react-router-dom';
import { AppDashboard } from './AppDashboard';
import { ApolloProvider } from './ApolloProvider';
import { useRefreshTokenBeforeExpiration } from './Hooks';
import { AppEmbed } from './Embed/AppEmbed';
import isProduction from './isProduction';
import { addMarkerIO } from './markerIO';
import { isLoggedIn } from './utils';

if (!isProduction) {
  void addMarkerIO();
}

const useEmbedApp = process.env.REACT_APP_EMBED_APP === 'true';

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <ApolloProvider>
        <Router>
          {useEmbedApp ? (
            <AuthCheck>
              <AppEmbed />
            </AuthCheck>
          ) : (
            <AppDashboard />
          )}
        </Router>
      </ApolloProvider>
    </React.StrictMode>
  );
};

const AuthCheck = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const isOnLoginPage = location.pathname.startsWith('/login');

  useRefreshTokenBeforeExpiration(1000 * 60 * 6);

  if (!isOnLoginPage && !isLoggedIn()) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  return children ?? <></>;
};

export default App;
